@use "../../../../theme";
@use "../../../../breakpoints";
.skillFullContainer {
    height: 80px;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}
.techItem {
    z-index: 2;
    position: relative;
    display: flex;
    width: fit-content;
    max-width: 80px;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    transition: 0.5s ease-in-out;
    margin-right: 9px;
}
.techItemBackground {
    height: 60px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
    background-color: theme.$secondary;
    box-shadow: 2px 2px 24px rgba($color: #000000, $alpha: 0.8);
}
.techItem:hover {
    max-width: 100%;;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-right: 0px;
}
// https://codepen.io/cuonglh911/pen/wMwbZQ
.skillItemIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -4px;
    margin: 0 0;
    width: 88px;
}
.shape {
    stroke-dasharray: 0 540;
    stroke-dashoffset: -474;
    stroke-width: 14px;
    stroke: theme.$alternate;
    border-radius: 100%;
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s, fill 0.5s ease-in-out;
    fill: theme.$highlight;
}
.techItem:hover .shape {
    fill: theme.$primary;
    stroke-width: 4px;
    stroke-dashoffset: 0;
    stroke-dasharray: 760;
    border-radius: 100%;
}
.skillItemTextContainer {
    display: flex;
    align-items: center;
    height: 100%;
    right: 0px;
    opacity: 0%;
    transition: 0.5s ease-in-out;
    padding-right: 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    float: left; 
    white-space: nowrap;
    overflow: hidden;
}
.techItem:hover .skillItemTextContainer {
    opacity: 100%;
}
.skillItemImage {
    fill: theme.$alternate;
    position: absolute;
    width: 60px;
    background-repeat: "no-repeat";
    background-size: cover;
}
.skillItemText {
    font-weight: bold;
    color: theme.$alternate;
    font-size: 16px;
    margin-left: 100px;
}
.techItem:hover ~ .skillOuterLabel {
    opacity: 0%;
}
.skillOuterLabel {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    opacity: 100%;
    transition: 0.3s ease-in-out;
    color: theme.$alternate;
    font-size: 16px;
    font-family: 'Courier New', Courier, monospace;
}
.skillLabelLeft {
    padding-right: 60px;
    text-align: end;
}
.skillLabelRight {
    padding-left: 60px;
    text-align: start;
}

@media (max-width: 383px) {
    .skillItemText {
        font-size: 12px;
    }
    .skillOuterLabel {
        font-size: 14px;
    }
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
    .skillFullContainer {
        margin-bottom: 24px;
        width: 700px;
    }
    .techItem:hover {
        max-width: 100%;
    }
    .skillItemText {
        margin-left: 110px;
    }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
    .techItem {
        height: 100%;
        width: fit-content;
        max-width: 90px;
        margin-right: 15px;
    }
    .skillFullContainer {
        margin-bottom: 24px;
        width: 840px;
    }
    .skillItemTextContainer {
        padding-right: 34px;
    }
    .skillItemIcon {
        left: -3px;
        width: 100px;
    }
    .skillItemImage {
        width: 70px;
    }
    .skillItemText {
        font-size: 20px;
        margin-left: 120px;
    }
    .skillLabelLeft {
        padding-right: 70px;
        text-align: end;
    }
    .skillLabelRight {
        padding-left: 70px;
        text-align: start;
    }
    .skillFullContainer {
        margin-bottom: 40px;
    }
    .skillOuterLabel {
        font-size: 24px;
    }
    .techItemBackground {
        height: 70px;
    }
}

/* ******************* SMALL MOBILE VIEW ******************* */
@media (max-width: 340px) {
    .techItem {
        height: 100%;
        width: fit-content;
        max-width: 60px;
        margin-right: 3px;
    }
    .skillItemTextContainer {
        padding-right: 20px;
    }
    .skillItemIcon {
        left: -3px;
        width: 66px;
    }
    .skillItemImage {
        width: 40px;
    }
    .skillItemText {
        font-size: 10px;
        margin-left: 75px;
    }
    .skillOuterLabel {
        font-size: 11px;
    }
    .skillLabelLeft {
        padding-right: 45px;
        text-align: end;
    }
    .skillLabelRight {
        padding-left: 45px;
        text-align: start;
    }
    .techItemBackground {
        height: 45px;
    }
}