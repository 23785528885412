.enter-contact {
    animation: enter-contact 0.2s linear forwards;
  }
.leave-contact {
    animation: leave-contact 0.2s linear forwards;
}
@keyframes enter-contact {
    0% {
        opacity: 0%;
        transform: translateY(20px);
    }
    100% {
        opacity: 100%;
        transform: translateY(0px);
    }
}
@keyframes leave-contact {
    0% {
        opacity: 100%;
        transform: translateY(0px);
    }
    100% {
        opacity: 0%;
        transform: translateY(20px);
    }
}