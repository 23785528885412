@use "../../../../../theme";
@use "../../../../../breakpoints";

.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: normal;
  text-align: center;
  padding: 18px;
}
.previewImage {
  width: 220px;
  height: 110px;
  margin-bottom: 12px;
  background-repeat: "no-repeat";
  background-size: cover;
}
.description {
  color: theme.$alternate;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}
.tellMeMore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 32px;
  background-color: transparent;
  margin-top: 18px;
  border: 2px theme.$alternate solid;
  border-radius: 8px;
  color: theme.$alternate;
  font-size: 16px;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;
}
.tellMeMore:hover {
  background-color: theme.$alternate;
  color: theme.$primary;
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .preview {
    padding: 20px;
  }
  .previewImage {
    margin-bottom: 12px;
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .preview {
    padding: 30px;
  }
  .previewImage {
    width: 300px;
    height: 150px;
    margin-bottom: 12px;
  }
  .description {
    font-size: 32px;
    line-height: 36px;
  }
  .tellMeMore {
    width: 160px;
    height: 36px;
    border: 2px theme.$alternate solid;
    border-radius: 8px;
    font-size: 20px;
  }
}

/* ******************* DESKTOP VIEW 2 ******************* */
@media (min-width: 980px) {
  .description {
    font-size: 36px;
    line-height: 40px;
  }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
  .previewImage {
    width: 350px;
    height: 175px;
    margin-bottom: 18px;
  }
  .description {
    font-size: 36px;
    line-height: 40px;
  }
  .tellMeMore {
    margin-top: 24px;
  }
}

/* ******************* SMALL MOBILE VIEW ******************* */
@media (max-width: breakpoints.$small-mobile) {
  .previewImage {
    width: 160px;
    height: 80px;
    margin-bottom: 10px;
  }
  .description {
    font-size: 24px;
    line-height: 28px;
  }
}
