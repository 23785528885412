@use "../../../../theme";
@use "../../../../breakpoints";

.contact {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  z-index: 1;
}
.formContainer {
  margin-top: 36px;
  padding-bottom: 64px;
  height: fit-content;
  display: flex;
  transition: 0.3s ease-in-out;
  z-index: 1;
}
.sectionTitle {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 8px;
  z-index: 1;
}
.formSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
.shadowContainer {
  box-shadow: 2px 2px 24px rgba($color: #000000, $alpha: 0.8);
}
.contactInfoContainer {
  background-color: theme.$highlight;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.infoText {
  color: theme.$alternate;
}
.infoDetails {
  font-size: 16px;
  margin-top: 8px;
}
.formText {
  color: theme.$primary;
}
.inputContainer {
  background-color: theme.$alternate;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
input {
  font-family: Helvetica, Arial, sans-serif;
}
textarea {
  resize: none;
  height: 200px;
  font-family: Helvetica, Arial, sans-serif;
}
.infoIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
}
.infoIcon {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  box-shadow: 1px 1px 10px rgba($color: #000000, $alpha: 0.7);
  background-repeat: "no-repeat";
  background-size: cover;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.infoIcon:hover {
  transform: scale(1.2);
  box-shadow: 1px 1px 7px rgba($color: #000000, $alpha: 0.6);
}
#githubIcon {
  margin-left: 12px;
  margin-right: 12px;
}
#emailToCopy {
  display: none;
}
.form {
  display: flex;
  flex-direction: column;
}
.formTitle {
  font-weight: bold;
  margin-bottom: 6px;
}
.formText {
  font-size: 16px;
}
.inputBox {
  margin-bottom: 12px;
  max-height: 100px;
  border-radius: 3px;
  outline: none;
  border: 1px solid theme.$primary;
  min-height: 23px;
}
.formSubmitButton {
  text-align: center;
  cursor: pointer;
  width: 150px;
  height: 40px;
  align-self: center;
  background-color: theme.$alternate;
  border-radius: 100px;
  border: theme.$primary 1px solid;
  color: theme.$alternate;
  font-weight: bold;
  margin-top: 4px;
  font-size: 16px;
  background-color: theme.$primary;
  box-shadow: 1px 1px 10px rgba($color: #000000, $alpha: 0.6);
  transition: 0.3s ease-in-out;
}

#buttonDisabled {
  cursor: not-allowed;
  opacity: 50%;
  box-shadow: none;
}
#buttonDisabled {
  transform: none;
}
.formSubmitButton:hover {
  transform: scale(1.1);
  box-shadow: 1px 1px 7px rgba($color: #000000, $alpha: 0.6);
}
.formSubmitButton:focus {
  border-radius: 100px;
}

input:focus {
  outline: none;
  border: 1px solid theme.$highlight;
  border-radius: 3px;
}
textarea:focus {
  outline: none;
  border: 1px solid theme.$highlight;
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .contact {
    align-items: center;
  }
  .formContainer {
    padding-bottom: 36px;
  }
  .formSection {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .shadowContainer {
    display: flex;
  }
  .contactInfoContainer {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    justify-content: space-between;
  }
  .infoIcon {
    height: 45px;
    width: 45px;
  }
  #githubIcon {
    margin-left: 10px;
    margin-right: 10px;
  }
  .infoDetails {
    margin-top: 8px;
  }
  .inputContainer {
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  textarea {
    height: 250px;
  }
  .inputBox {
    margin-bottom: 12px;
    max-height: 125px;
    border-radius: 3px;
    outline: none;
    border: 1px solid theme.$primary;
    min-height: 23px;
  }
}

/* ******************* VIEW WHEN BURGER MENU HIDDEN ******************* */
@media (min-width: breakpoints.$hide-burger-menu) {
  .contactInfoContainer {
    width: 45%;
  }
  .inputContainer {
    width: 55%;
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .contact {
    width: 100%;
  }
  .formContainer {
    margin-left: 40px;
    margin-right: 40px;
    max-width: 800px;
    margin-top: 48px;
  }
  .contactInfoContainer {
    width: 40%;
  }
  .inputContainer {
    width: 60%;
  }
  .sectionTitle {
    font-size: 40px;
    margin-bottom: 10px;
    line-height: 42px;
  }
  .formSection {
    box-sizing: border-box;
    padding: 32px;
  }
  .shadowContainer {
    box-shadow: 3px 3px 32px rgba($color: #000000, $alpha: 0.9);
  }
  .infoDetails {
    font-size: 20px;
    margin-top: 10px;
  }
  textarea {
    resize: vertical;
    height: 100px;
  }
  .infoIcon {
    height: 50px;
    width: 50px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 1px 1px 14px rgba($color: #000000, $alpha: 0.7);
  }
  .infoIcon:hover {
    box-shadow: 1px 1px 7px rgba($color: #000000, $alpha: 0.8);
  }
  .formTitle {
    font-size: 20px;
  }
  .formText {
    font-size: 20px;
  }
  .inputBox {
    max-height: 200px;
  }
  .formSubmitButton {
    width: 170px;
    height: 45px;
    box-shadow: 2px 2px 14px rgba($color: #000000, $alpha: 0.6);
  }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
  .formContainer {
    max-width: 900px;
  }
  .contactInfoContainer {
    width: 35%;
  }
  .inputContainer {
    width: 65%;
  }
  .infoIcon {
    margin-left: 14px;
    margin-right: 14px;
  }
}
