@use "../../../../theme";
@use "../../../../breakpoints";

h1 {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 8px;
  display: inline;
  transition: 0.3s ease-in-out;
}
h2 {
  font-size: 32px;
  font-weight: normal;
  display: inline;
  position: relative;
  z-index: 2;
  transition: 0.3s ease-in-out;
}
.highlightText {
  color: theme.$highlight;
}
.greetingLetter {
  transition: 0.3s ease-in-out;
}
.greetingLetter:hover {
  color: theme.$highlight;
}
.highlightText:hover {
  color: theme.$alternate;
}
.greeting {
  color: theme.$alternate;
  text-align: center;
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  h1 {
    font-size: 48px;
    padding-bottom: 8px;
  }
  h2 {
    font-size: 40px;
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  h1 {
    font-size: 72px;
    padding-bottom: 8px;
  }
  h2 {
    font-size: 56px;
  }
}
