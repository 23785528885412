@use "../../../../theme";
@use "../../../../breakpoints";

.bioSection {
    width: 100%;
    opacity: 0%;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.bioCloseButtonContainer {
    opacity: 0%;
    position: fixed;
    top: 78px;
    right: 24px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    border-radius: 100%;
    box-shadow: 2px 2px 16px rgba($color: #000000, $alpha: 0.5);
  }
  .bioCloseButtonContainer:hover {
        transform: scale(120%);
        -webkit-transform: scale(1.2, 1.2);
  }
  .bioTextContainer {
        height: fit-content;
        display: flex;
        flex-direction: column;
        width: 280px;
        background-color: theme.$secondary;
        padding: 15px;
        padding-left: 18px;
        padding-left: 18px;
        border-radius: 16px;
        box-shadow: 2px 2px 24px rgba($color: #000000, $alpha: 0.7);
  }
  .bioText {
        color: theme.$alternate;
        font-size: 18px;
  }
  .bioIntroText {
        margin-bottom: 12px;
  }


/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
    .bioCloseButtonContainer {
        right: 40px;
    }
    .bioTextContainer {
        height: fit-content;
        width: 382px;
        padding: 17px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 20px;
    }
    .bioText {
            font-size: 20px;
    }
    .bioIntroText {
            margin-bottom: 16px;
    }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
    .bioCloseButtonContainer {
        top: 100px;
        right: 68px;
        width: 55px;
        height: 55px;
    }
    .bioTextContainer {
        width: 625px;
        padding: 20px;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 20px;
    }
    .bioText {
            font-size: 28px;
    }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
    .bioCloseButtonContainer {
        top: 120px;
        right: 80px;
        width: 60px;
        height: 60px;
    }
    .bioTextContainer {
        width: 850px;
        padding: 25px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 30px;
    }
    .bioText {
            font-size: 34px;
    }
    .bioIntroText {
            margin-bottom: 20px;
    }
}

@media (max-width: breakpoints.$small-mobile) {
    .bioCloseButtonContainer {
        top: 78px;
        right: 20px;
        width: 42px;
        height: 42px;
    }
    .bioTextContainer {
        width: 200px;
        padding: 13px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 10px;
    }
    .bioText {
            font-size: 15px;
    }
    .bioIntroText {
            margin-bottom: 12px;
    }
}
