.menu-open {
  animation: open-menu 0.5s 0.75s linear forwards;
}
.menu-closed {
  animation: close-menu 1.5s linear forwards;
}
.show-greeting {
  animation: show-greeting 2.25s linear forwards;
}
.show-greeting-initial {
  animation: show-greeting-initial 0.9s linear forwards;
}
.hide-greeting {
  animation: hide-greeting 0.65s linear forwards;
}
.show-footer {
  animation: show-footer 2.25s linear forwards;
}
.show-footer-initial {
  animation: show-footer-intial 0.9s linear forwards;
}
.hide-footer {
  pointer-events: none;
  animation: hide-footer 0.65s linear forwards;
}

@keyframes open-menu {
  0% {
    width: 0;
  }
  50% {
    width: 3%;
  }
  65% {
    width: 10%;
  }
  80% {
    width: 20%;
  }
  100% {
    width: 100%;
  }
}
@keyframes close-menu {
  0%,
  66% {
    width: 100%;
  }
  80% {
    width: 20%;
  }
  81% {
    width: 10%;
  }
  83% {
    width: 3%;
  }
  100% {
    width: 0;
  }
}
@keyframes show-greeting {
  0%,
  70% {
    display: block;
    transform: translateY(-75%);
  }
  77% {
    transform: translateY(-5%);
  }
  81% {
    transform: translateY(2.5%);
  }
  83% {
    transform: translateY(4%);
  }
  84% {
    transform: translateY(5%);
  }
  85% {
    transform: translateY(4.5%);
  }
  87% {
    transform: translateY(4%);
  }
  91%,
  100% {
    transform: translateY(0%);
  }
}
@keyframes show-greeting-initial {
  0% {
    transform: translateY(-75%);
  }
  20% {
    transform: translateY(-10%);
  }
  25% {
    transform: translateY(0%);
  }
  32% {
    transform: translateY(4%);
  }
  36% {
    transform: translateY(5%);
  }
  40% {
    transform: translateY(4.5%);
  }
  42% {
    transform: translateY(4%);
  }
  55% {
    transform: translateY(0%);
  }
}
@keyframes hide-greeting {
  0% {
    transform: translateY(0%);
  }
  33% {
    transform: translateY(4%);
  }
  44% {
    transform: translateY(4.5%);
  }
  50% {
    transform: translateY(5%);
  }
  65% {
    transform: translateY(4%);
  }
  75% {
    transform: translateY(2.5%);
  }
  80% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(-75%);
  }
}
@keyframes show-footer {
  0%,
  70% {
    pointer-events: none;
    bottom: -170px;
  }
  77% {
    bottom: -80px;
  }
  81% {
    bottom: 25px;
  }
  83% {
    bottom: 40px;
  }
  84% {
    bottom: 50px;
  }
  85% {
    bottom: 45px;
  }
  87% {
    bottom: 40px;
  }
  91%,
  100% {
    pointer-events: all;
    bottom: 0;
  }
}
@keyframes show-footer-intial {
  0% {
    bottom: -200px;
  }
  12% {
    bottom: -140px;
  }
  22% {
    bottom: -30px;
  }
  28% {
    bottom: 40px;
  }
  30% {
    bottom: 50px;
  }
  40% {
    bottom: 45px;
  }
  42% {
    bottom: 40px;
  }
  55% {
    bottom: 0;
  }
}
@keyframes hide-footer {
  0% {
    pointer-events: none;
    bottom: 0;
  }
  33% {
    bottom: 40px;
  }
  44% {
    bottom: 45px;
  }
  50% {
    bottom: 50px;
  }
  65% {
    bottom: 40px;
  }
  75% {
    bottom: 25px;
  }
  90% {
    bottom: -140px;
  }
  100% {
    pointer-events: none;
    bottom: -200px;
  }
}
