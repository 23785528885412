@use "../../../../../theme";
@use "../../../../../breakpoints";

/* Credit to: https://codepen.io/rsbear/pen/edGEbd */
.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 100%;
}

// These prevent endless spinning on Firefox mobile devices
@-moz-document url-prefix() {
  #hovering {
    animation: none;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    #hovering {
      animation: none;
    }
  }
}

#hovering {
  animation: spinCloseButton 2s 0.5s infinite;
}

.leftright {
  height: 3px;
  width: 32px;
  position: absolute;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.5s ease-in-out;
}

.rightleft {
  height: 3px;
  width: 32px;
  position: absolute;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.5s ease-in-out;
}

.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.closeButton:hover .leftright {
  transform: rotate(-180deg);
}
.closeButton:hover .rightleft {
  transform: rotate(180deg);
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .leftright {
    height: 3px;
    width: 32px;
  }

  .rightleft {
    height: 3px;
    width: 32px;
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .leftright {
    height: 4px;
    width: 38px;
  }

  .rightleft {
    height: 4px;
    width: 38px;
  }
}
