@use "../../../../theme";
@use "../../../../breakpoints";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.triangle {
  position: absolute;
  top: 32px;
  width: 0;
  height: 0;
  border-left: 45px solid transparent;
  border-right: 45px solid transparent;
  border-bottom: 35px solid;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.squares {
  position: absolute;
  top: 5px;
  width: 100px;
  height: 100px;
  transform-origin: center center;
  transition: 0.4s ease-in-out;
}
@keyframes spinSquare1 {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
@keyframes spinSquare2 {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(0.8);
  }
}
#tri1:hover ~ .squares {
  background-color: transparent;
}
#square1 {
  background: rgba(255, 255, 255, 0.04);
  animation: spinSquare1 9s infinite linear;
  animation-direction: reverse;
}
#square2 {
  background: rgba(255, 255, 255, 0.02);
  animation: spinSquare1 9s infinite linear;
}
#tri1 {
  border-bottom-color: theme.$highlight;
  z-index: 3;
}
#tri2 {
  border-bottom-color: theme.$secondary;
  z-index: 2;
}
#tri3 {
  border-bottom-color: theme.$alternate;
  z-index: 1;
}
#tri1:hover ~ #tri2 {
  transform: translateY(-13px);
}
#tri1:hover ~ #tri3 {
  transform: translateY(13px);
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .footer {
    height: 150px;
  }
  .squares {
    top: 7px;
    width: 110px;
    height: 110px;
  }
  .triangle {
    top: 28px;
    border-left: 55px solid transparent;
    border-right: 55px solid transparent;
    border-bottom: 45px solid;
    transition: 0.3s ease-in-out;
  }
  #tri1:hover ~ #tri2 {
    transform: translateY(-16px);
  }
  #tri1:hover ~ #tri3 {
    transform: translateY(16px);
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .footer {
    height: 170px;
  }
  .squares {
    top: 0px;
    width: 120px;
    height: 120px;
  }
  #tri1:hover ~ #tri2 {
    transform: translateY(-18px);
  }
  #tri1:hover ~ #tri3 {
    transform: translateY(18px);
  }
}
