// from: https://codepen.io/Rowno/pen/EVEgJb
.background {
  &:after,
  &:before {
    content: "";
    display: block;
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%; // IE/Edge
    height: 100%; // fallback
    width: 100vmax;
    height: 100vmax;
    background: rgba(0, 0, 0, 0.1);
    animation: background 60s linear infinite;
  }

  &:after {
    left: 15%;
  }

  &:before {
    right: 15%;
    animation-delay: -20s;
    animation-direction: reverse;
  }
}

@keyframes background {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
