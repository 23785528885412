@use "../../../../../theme";
@use "../../../../../breakpoints";

.previewCarousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow: hidden;
}
.previewContainer {
  transform-origin: bottom;
  overflow: hidden;
  background-color: theme.$primary;
  box-shadow: 2px 2px 16px rgba($color: #000000, $alpha: 0.6);
  border-radius: 20px;
}
#preview-transitioning {
  animation: previewTransitioning 0.5s forwards;
}
.toggleSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.optionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-left: 1px;
  margin-right: 1px;
  z-index: 1;
}
.option {
  transform: rotate(45deg);
  width: 16px;
  height: 16px;
  border: 2px theme.$alternate solid;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.option:hover {
  background-color: rgba(255, 255, 255, 0.5);
  transform: rotate(135deg);
}

#selected {
  background-color: theme.$alternate;
  animation: spinSelectedOption 0.5s ease-in-out;
}

@keyframes previewTransitioning {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-450px);
  }
  51% {
    display: none;
  }
  52% {
    display: block;
    transform: translateY(-450px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes spinSelectedOption {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(135deg);
  }
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .toggleSection {
    margin-top: 16px;
  }

  @keyframes previewTransitioning {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-350px);
    }
    51% {
      display: none;
    }
    52% {
      display: block;
      transform: translateY(-350px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .toggleSection {
    margin-top: 28px;
  }
  @keyframes previewTransitioning {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-500px);
    }
    51% {
      display: none;
    }
    52% {
      display: block;
      transform: translateY(-500px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
  @keyframes previewTransitioning {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-1500px);
    }
    51% {
      display: none;
    }
    52% {
      display: block;
      transform: translateY(-1500px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}

/* ******************* SMALL MOBILE VIEW ******************* */
@media (max-width: breakpoints.$small-mobile) {
  .optionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    margin-left: 1px;
    margin-right: 1px;
  }
  .option {
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
    border: 2px theme.$alternate solid;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }
}
