@use "../../../../theme";
@use "../../../../breakpoints";

.home {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  z-index: 1;
}
.greetingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  z-index: 1;
}
.footerContainer {
  position: absolute;
  bottom: 0px;
  height: 125px;
  z-index: 1;
}
.menuModalContainer {
  height: 100%;
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  overflow: hidden;
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .home {
    padding-left: 50px;
    padding-right: 50px;
  }
  .footerContainer {
    height: 150px;
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .home {
    padding-left: 65px;
    padding-right: 65px;
  }
  .footerContainer {
    height: 170px;
  }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
}
