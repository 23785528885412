@use "../../../../../theme";
@use "../../../../../breakpoints";

.menuModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background-color: theme.$highlight;
}
.previewsContainer {
  width: 85%;
}
.closeButtonContainer {
  position: fixed;
  top: 80px;
  left: 30px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  margin-bottom: 100px;
  border-radius: 100%;
  box-shadow: 2px 2px 16px rgba($color: #000000, $alpha: 0.5);
}

.closeButtonContainer:hover {
  transform: scale(120%);
  -webkit-transform: scale(1.2, 1.2);
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .closeButtonContainer {
    left: 30px;
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .previewsContainer {
    width: 65%;
  }
  .closeButtonContainer {
    top: 100px;
    left: 58px;
    width: 55px;
    height: 55px;
  }
}

/* ******************* DESKTOP VIEW 2 ******************* */
@media (min-width: 980px) {
  .previewsContainer {
    width: 55%;
  }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
  .closeButtonContainer {
    left: 66px;
    width: 60px;
    height: 60px;
    top: 120px;
  }
}
