* {
  margin: 0;
  /* max-width: 100%; */
  /* max-height: 100%; */
  /* overflow: hidden; */
  /* overflow-x: hidden; */
  /* overscroll-behavior-x: none; */
}
.Toastify__toast-container {
  opacity: 95%;
}
.Toastify__toast-body {
  color: #252a37;
  font: "Helvetica Neue";
  font-weight: bold;
}
body {
  background-color: #252a37;
  min-width: 280px;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
