@use "../../../../theme";
@use "../../../../breakpoints";

.tech {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    z-index: 1;
    overflow-x: hidden;
}
.techTitle {
    color: theme.$alternate;
    font-weight: bold;
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
    white-space: normal;
}
.techItemsContainer {
    display: flex;
    height: fit-content;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
    .techItemsContainer {
        padding-bottom: 40px;
    }
}

@media (min-width: breakpoints.$hide-burger-menu) {
    .techTitle {
        white-space: nowrap;
    }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
    .techItemsContainer {
        margin-top: 40px;
    }
    .techTitle {
       font-size: 40px;
       margin-bottom: 40px;
    }
}

/* ******************* SMALL MOBILE VIEW ******************* */
@media (max-width: 340px) {
    .techItemsContainer {
        margin-top: 24px;
    }
    .techTitle {
       font-size: 24px;
       margin-bottom: 24px;
    }
}