@use "../../../theme";
@use "../../../breakpoints";
@use "../../../headerHeight";

#desktopHeader {
  display: none;
}

.linksSection {
  overflow: visible;
}

.burgerMenu {
  overflow: visible;
}

.header {
  z-index: 2;
  display: block;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: headerHeight.$mobile;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: theme.$secondary;
  overflow: hidden;
  box-shadow: 2px 2px 16px rgba($color: #000000, $alpha: 0.5);
}

.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(80, 89, 109, 0.95);
  overflow-y: hidden;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
}

.overlay-content {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay ul {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  font-weight: bold;
  color: theme.$alternate;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

.overlay ul:hover,
.overlay ul:focus {
  color: theme.$primary;
  background-color: theme.$alternate;
}

.overlay .closebtn {
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: 16px;
  font-size: 60px;
  padding: 8px;
  text-decoration: none;
  color: theme.$alternate;
  display: block;
  transition: 0.3s;
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
  .categoryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }

  .links {
    text-decoration: none;
    color: theme.$alternate;
    font-weight: bold;
    font-size: 16px;
    margin-right: 7.5px;
    margin-left: 7.5px;
    transition-timing-function: ease-in-out;
    transition-duration: 0.25s;
  }

  .links:hover {
    opacity: 50%;
    transform: translateY(-2px);
  }

  .selected {
    color: theme.$alternate;
  }

  .spacer {
    background-color: theme.$alternate;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    transform: rotate(45deg);
  }

  .selectionIndicator {
    position: absolute;
    bottom: 10px;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 8px solid theme.$highlight;
  }

  .selectorDown {
    animation: selectorDown 0.25s linear;
  }

  .selectorUp {
    animation: selectorUp 0.25s linear;
  }

  @keyframes selectorDown {
    0% {
      bottom: 10px;
    }
    50% {
      bottom: 5px;
    }
    100% {
      bottom: -15px;
    }
  }

  @keyframes selectorUp {
    0% {
      bottom: -15px;
    }
    50% {
      bottom: 5px;
    }
    100% {
      bottom: 10px;
    }
  }
}

/* ******************* HIDE BURGER MENU BREAKPOINT ******************* */
@media (min-width: breakpoints.$hide-burger-menu) {
  .burgerMenu {
    display: none;
  }

  #desktopHeader {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .header {
    height: headerHeight.$desktop;
  }

  .links {
    font-size: 20px;
    margin-right: 15px;
    margin-left: 15px;
  }

  .spacer {
    width: 6px;
    height: 6px;
  }

  #desktopHeader {
    margin-right: 50px;
  }

  .selectionIndicator {
    bottom: 14px;
  }

  @keyframes selectorDown {
    0% {
      bottom: 14px;
    }
    50% {
      bottom: 5px;
    }
    100% {
      bottom: -15px;
    }
  }

  @keyframes selectorUp {
    0% {
      bottom: -15px;
    }
    50% {
      bottom: 5px;
    }
    100% {
      bottom: 14px;
    }
  }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
  .header {
    height: headerHeight.$big-desktop;
  }

  .links {
    font-size: 22px;
    margin-right: 18px;
    margin-left: 18px;
  }

  .spacer {
    width: 6px;
    height: 6px;
  }

  #desktopHeader {
    margin-right: 55px;
  }

  .selectionIndicator {
    bottom: 16px;
  }

  @keyframes selectorDown {
    0% {
      bottom: 16px;
    }
    50% {
      bottom: 5px;
    }
    100% {
      bottom: -18px;
    }
  }

  @keyframes selectorUp {
    0% {
      bottom: -18px;
    }
    50% {
      bottom: 5px;
    }
    100% {
      bottom: 16px;
    }
  }
}
