@use "../../../theme";
@use "../../../breakpoints";

.iconContainer {
  width: 50px;
  height: 50px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 100%;
}
.iconContainer:hover .websiteIcon {
  transform: scale(-1) rotate(180deg);
  background-color: rgba($color: (theme.$alternate), $alpha: 0.1);
}

.websiteIcon {
  width: 100%;
  transition: 0.2s ease-in-out;
  border-radius: 100%;
}
/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
  .iconContainer {
    width: 62px;
    height: 62px;
    margin-left: 50px;
  }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
  .iconContainer {
    width: 72spx;
    height: 72px;
    margin-left: 55px;
  }
}
