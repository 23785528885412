@use "../../../../theme";
@use "../../../../breakpoints";

.about {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
}
.bioButton {
    cursor: pointer;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: theme.$alternate;
    top: 74px;
    right: 24px;
    width: 80px;
    height: 40px;
    z-index: 4;
    border-radius: 8px;
    font-weight: bold;
    font-size: 20px;
    color: theme.$primary;
    box-shadow: 2px 2px 10px rgba($color: #000000, $alpha: 0.5);
    transition: 0.3s ease-in-out;
}
.bioButton:hover {
    background-color: theme.$primary;
    color: theme.$alternate;
    transform: scale(1.1);
}
.aboutPortalSection {
    width: 100%;
    position: relative;
    height: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: theme.$highlight;
    z-index: 2;
}
.aboutTopWire {
    position: absolute;
    top: 0;
    height: 50%;
    width: 8px;
    background-color: theme.$alternate;
    z-index: 1;
}
.aboutBottomPedestal {
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 80px;
    background-color: theme.$secondary;
    z-index: 1;
}
.aboutFullContainer {
    position: relative;
    width: 100%;
    background-color: theme.$highlight;
    width: 100%;
    height: 475px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: theme.$highlight;
    z-index: 2;
    overflow: hidden;
}
.aboutSignContainer {
    position: absolute;
    top: 0px;
    z-index: 2;
}
.aboutCarouselContainer {
    position: absolute;
    bottom: 85px;
    width: 100%;
    height: 74px;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.aboutPortalContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.aboutPedestalContainer {
    position: absolute;
    bottom: -5px;
    width: calc(100% - 40px);
    height: 35px;
    background-color: theme.$secondary;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
}
.aboutPedestalTop {
    width: 100%;
    height: 16px;
    margin-top: -8px;
    border-radius: 100%;
    background-color: theme.$primary;
}
.tubeSideBaseConnector {
    bottom: 27px;
    position: absolute;
    height: 58px;
    z-index: 3;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}
.tubeSideBasePole {
    width: 6px;
    height: 58px;
    background-color: theme.$secondary;
}
.tubeSideBaseConnectorLeft {
    padding-right: 206px;
}
.tubeSideBaseConnectorRight {
    padding-left: 206px;
}

/* ******************* TABLET VIEW ******************* */
@media (min-width: breakpoints.$tablet) {
    .aboutPedestalContainer {
        width: 440px;
    }
}

/* ******************* DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$desktop) {
    .bioButton {
        top: 94px;
        right: 66px;
        width: 76px;
        height: 42px;
        font-size: 22px;
    }
}

/* ******************* BIG DESKTOP VIEW ******************* */
@media (min-width: breakpoints.$big-desktop) {
    .aboutFullContainer {
        height: 600px;
    }
    .aboutCarouselContainer {
        bottom: 120px;
        height: 100px;
    }
    .tubeSideBaseConnector {
        bottom: 28px;
        height: 92px;
    }
    .tubeSideBasePole {
        width: 8px;
        height: 92px;
        background-color: theme.$secondary;
    }
    .tubeSideBaseConnectorLeft {
        padding-right: 280px;
    }
    .tubeSideBaseConnectorRight {
        padding-left: 280px;
    }
    .aboutPedestalContainer {
        width: 600px;
    }
    .aboutTopWire {
        width: 9px;
    }
    .aboutBottomPedestal {
        width: 130px;
    }
    .bioButton {
        top: 114px;
        right: 78px;
        width: 80px;
        height: 44px;
        font-size: 24px;
    }
}

@media (max-width: breakpoints.$small-mobile) {
    .aboutCarouselContainer {
        height: 55px;
    }
    .tubeSideBasePole {
        width: 5px;
        height: 55px;
        background-color: theme.$secondary;
    }
    .tubeSideBaseConnectorLeft {
        padding-right: 146px;
    }
    .tubeSideBaseConnectorRight {
        padding-left: 146px;
    }
    .bioButton {
        top: 74px;
        right: 20px;
        width: 70px;
        height: 34px;
        font-size: 18px;
    }
}
